import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { useState, useEffect } from "react"; // Import useState and useEffect
import { api } from "~/utils/api";
import { Inter, Major_Mono_Display } from "next/font/google";
import Head from "next/head";
import { useDarkMode } from "~/hooks/useDarkMode";
import { CampaignsProvider } from "~/providers/CampaignsProvider";
import { LoggerProvider } from "~/providers/LoggerProvider";
import SidebarProvider from "~/providers/SidebarProvider";
import { TeamAccountProvider } from "~/providers/TeamAccountProvider";
import { TeamAccountUserProvider } from "~/providers/TeamAccountUserProvider";
import MobileScreen from "~/pages/mobileScreen";
import "~/styles/globals.css";
const inter = Inter({
  subsets: ["latin"],
  variable: "--inter",
});
const majorMonoDisplay = Major_Mono_Display({
  subsets: ["latin"],
  weight: "400",
  variable: "--major-mono-display",
});
const App: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  useDarkMode();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <div
      className={inter.variable.concat(" ").concat(majorMonoDisplay.variable)}
    >
      <Head>
        <title>OpenQ DRM</title>
        <meta
          name="OpenQ DRM"
          content="width=device-width, initial-scale=1.0"
        />
        <link rel="icon" href="/openq-logo.png" />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
      </Head>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>

      <SessionProvider session={session}>
        <TeamAccountUserProvider>
          <LoggerProvider>
            <TeamAccountProvider>
              <CampaignsProvider>
                <SidebarProvider>
                  {isMobile ? <MobileScreen /> : <Component {...pageProps} />}
                </SidebarProvider>
              </CampaignsProvider>
            </TeamAccountProvider>
          </LoggerProvider>
        </TeamAccountUserProvider>
      </SessionProvider>
    </div>
  );
};
export default api.withTRPC(App);
