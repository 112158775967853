// CLIENT Configuration
// uses defaults

// Kafka Client Id
export const KAFKA_PRODUCER_CLIENT_ID =
  "ccloud-nodejs-client-b687b59c-f103-40b8-8dba-84755d2dc71a";

// KAFKA CONSUMER CONFIGURATION

// CONSUMER Configuration
// This section contains properties related to the Kafka consumer configuration.
// ANY PROPERTY NOT EXPLICITLY DEFINED HERE WILL BE THE DEFAULT CONFLUENT KAFKA JAVASCRIPT CONFIGURATION

export const PARTITIONS_CONSUMED_CONCURRENTLY = 1;

export const CONSUMER_SESSION_TIMEOUT = 120000;
export const MAX_POLL_INTERVAL = 2000000;
export const HEARTBEAT_INTERVAL = 3000;
export const INITIAL_RETRY_TIME = 100;
export const RETRY_BACKOFF = 10000;
export const RECONNECT_BACKOFF = 1000;
export const RECONNECT_BACKOFF_MAX = 10000;
export const RETRIES = 20;
export const PARTITION_ASSIGNMENT_STRATEGY = "cooperative-sticky";
export const MAX_FAILURE_RECONNECTS = 10;
export const FAILIURE_RECONECT_INTERVAL = 3000;

// TOPICS
export const TARGET_BATCH_CREATIONS_TOPIC = "target-batch-creations";
export const USER_EVALUATION_TOPIC = "user-evaluations";
export const REPO_EVALUATION_TOPIC = "repo-evaluations";
export const USER_DEPS_EVALUATION_TOPIC = "user-dependencies-evaluations";
export const REPO_DEPS_EVALUATION_TOPIC = "repo-dependencies-evaluations";
export const CAMPAIGN_EVALUATIONS_TOPIC = "campaign-evaluations";
export const OVERVIEW_EVALUATIONS_TOPIC = "overview-evaluations";
const getConsumerGroup = (topic: string) => `${topic}-consumer-group`;
// CONSUMER GROUP IDS
export const TARGET_BATCH_CREATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  TARGET_BATCH_CREATIONS_TOPIC
);

export const USER_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  USER_EVALUATION_TOPIC
);
export const REPO_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  REPO_EVALUATION_TOPIC
);
export const USER_DEPS_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  USER_DEPS_EVALUATION_TOPIC
);
export const REPO_DEPS_EVALUATIONS_CONSUMER_GROUP_ID = getConsumerGroup(
  REPO_DEPS_EVALUATION_TOPIC
);

export const CAMPAIGN_EVALUATIONS_CONSUMER_GROUP_ID =
  "campaign-evaluations-consumer-group";
export const OVERVIEW_EVALUATIONS_CONSUMER_GROUP_ID =
  "overview-evaluations-consumer-group";
