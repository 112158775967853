import { ONE_MINUTE } from "../constants";
import { GithubRateLimitsResponse } from "./types";

const reset = new Date().getTime() / 1000 + ONE_MINUTE;

export const mockFullRateLimit: GithubRateLimitsResponse = {
  resources: {
    core: {
      limit: 5000,
      remaining: 0,
      reset,
      used: 1,
    },
    graphql: {
      limit: 5000,
      remaining: 0,
      reset,
      used: 1,
    },
    search: {
      limit: 30,
      remaining: 0,
      reset,
      used: 1,
    },
  },
};
