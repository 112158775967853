export interface GitguruCommit {
  commit_hash: string;
  author: string;
  author_email: string;
  author_date: number;
  committer_date: number;
  message: string;
  repo_url: string;
  rest_id: number;
  email: string;
  internal_id: number;
  github_rest_id: number;
  github_graphql_id: string;
  login: string;
  name: string;
  email_2: string;
  avatar_url: string;
  company: string;
  location: string;
  bio: string;
  blog: string;
  hireable: boolean;
  twitter_username: string;
  followers: number;
  following: number;
  type: string;
  created_at: string;
  updated_at: string;
}

export interface RateLimit {
  limit: number;
  remaining: number;
  reset: number;
  used: number;
}

export interface GithubRateLimitsResponse {
  resources: {
    core: RateLimit;
    graphql: RateLimit;
    search: RateLimit;
  };
}

export function isGithubRateLimitsResponse(
  data: unknown
): data is GithubRateLimitsResponse {
  return (
    typeof data === "object" &&
    data !== null &&
    "resources" in data &&
    typeof data.resources === "object" &&
    data.resources !== null &&
    "core" in data.resources &&
    "graphql" in data.resources &&
    "search" in data.resources
  );
}
