import { z } from "zod";

export function isQueryResponseOfType<TResponse>(
  schema: z.Schema<TResponse>,
  data: unknown
): data is TResponse {
  const parse = schema.safeParse(data);

  if (parse.success) {
    return true;
  }

  console.error(JSON.stringify(parse.error));

  return false;
}
