import React, { createContext, useContext } from "react";
import useOnlineIndicator from "~/hooks/useOnlineIndicator";
import { api, type RouterOutputs } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";

interface CampaignContextType {
  campaigns: RouterOutputs["campaign"]["campaignsList"];
  handleRefetch: () => void;
}
const CampaignsContext = createContext<CampaignContextType>({
  campaigns: [],
  handleRefetch: () => null,
});

export function useCampaigns() {
  return useContext(CampaignsContext);
}

export function CampaignsProvider({ children }: { children: React.ReactNode }) {
  const { activeTeamAccount } = useTeamAccount();
  const logger = useLogger();
  const isOnline = useOnlineIndicator();

  const { data: campaigns, refetch } = api.campaign.campaignsList.useQuery(
    {
      teamAccountId: activeTeamAccount.id,
    },
    {
      enabled: isOnline,
    }
  );

  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "CampaignsProvider.tsx"));
  };

  if (!campaigns) return null;

  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        handleRefetch,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
}
