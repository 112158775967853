export const rateLimit = `rateLimit {
    limit
    used
    remaining
    resetAt
  }`;

export const pageInfo = `totalCount
pageInfo {
  hasNextPage
  endCursor
}`;
