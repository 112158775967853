import { useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import HasNoTeamAccountUser from "~/components/LandingPages/HasNoTeamAccountUser";
import { api, type RouterOutputs } from "~/utils/api";
export type User = RouterOutputs["teamAccountUser"]["getTeamAccountUser"];

type TeamAccountContextType = [
  teamAccountUser: User | null | undefined,
  updateTeamAccountUser: (teamAccountUser: User) => void | null,
];

const TeamAccountUserContext = createContext<TeamAccountContextType | null>(
  null
);

export function useTeamAccountUser() {
  return useContext(TeamAccountUserContext) as TeamAccountContextType;
}

export function TeamAccountUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [tryOnce, setTryOnce] = useState<boolean>(true);
  const teamAccountState = useState<User | null | undefined>();
  // source of truth for user
  const [teamAccountUser, setTeamAccountUser] = teamAccountState;

  // query for existing user.
  const {
    data: initialTeamAccountUser,
    refetch,
    isLoading: loadingQuery,
  } = api.teamAccountUser.getTeamAccountUser.useQuery(void 0, {
    refetchInterval: () => {
      if (teamAccountUser) {
        return false;
      }
      return 1000;
    },
    refetchOnWindowFocus: true,
  });
  const { data } = useSession();
  const accessToken = data?.accessToken;

  const mutationOptions = {
    onSuccess: (data: User) => {
      setTeamAccountUser(data);
    },
  };
  const { mutate, isPending } =
    api.teamAccountUser.upsertTeamAccountUser.useMutation(mutationOptions);

  useEffect(() => {
    console.log("refetch");
    if (initialTeamAccountUser) setTeamAccountUser(initialTeamAccountUser);
  }, [initialTeamAccountUser]);
  useEffect(() => {
    if (data?.user && !teamAccountUser && !isPending && !loadingQuery) {
      const { user } = data;
      console.log("base user", user);

      const { name, email, image } = user;
      if (name && email && tryOnce) {
        setTryOnce(false);

        mutate({ username: name, email, image });
      }
    }
  }, [
    data,
    mutate,
    refetch,
    teamAccountUser,
    isPending,
    tryOnce,
    loadingQuery,
    accessToken,
  ]);
  if (!teamAccountUser) {
    return <HasNoTeamAccountUser />;
  }
  return (
    <TeamAccountUserContext.Provider value={teamAccountState}>
      {children}
    </TeamAccountUserContext.Provider>
  );
}
