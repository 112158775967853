import { PrismaClient, PrismaTXN } from "@openqlabs/drm-db";
import { getCurrentTimestamp } from ".";

export default async function upsertOverviewEvaluation(
  prisma: PrismaClient | PrismaTXN,
  teamAccountId: string
) {
  try {
    await prisma.overviewEvaluation.upsert({
      where: {
        teamAccountId: teamAccountId,
      },
      create: {
        teamAccount: { connect: { id: teamAccountId } },
        status: "waiting",
        error: null,
        createdAt: getCurrentTimestamp(),
        updatedAt: getCurrentTimestamp(),
      },
      update: {
        status: "waiting",
        error: null,
        createdAt: getCurrentTimestamp(),
        updatedAt: getCurrentTimestamp(),
      },
    });
  } catch (error) {
    console.info(`Error updating overview evaluation to "waiting": ${error}`);
  }
}
