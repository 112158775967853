import type { MouseEventHandler } from "react";

export default function Button({
  children,
  onClick,
  disabled,
  className = "",
  type,
}: {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
}) {
  return (
    <button
      className={`${className} btn group flex items-center justify-center whitespace-nowrap rounded-md ${
        disabled
          ? "cursor-not-allowed border-none bg-vlight text-center text-offblack hover:bg-vlight"
          : ""
      }`}
      onClick={onClick}
      type={type ?? "button"}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
