import { GitguruUser } from "@openqlabs/drm-db";

export interface GitguruRepo {
  github_rest_id: number;
  github_graphql_id: string;
  name: string;
  full_name: string;
  private: boolean;
  owner_login: string;
  owner_avatar_url: string;
  description: string;
  homepage: string;
  fork: boolean;
  forks_count: number;
  archived: boolean;
  disabled: boolean;
  license: string;
  language: string;
  stargazers_count: number;
  watchers_count: number;
  open_issues_count: number;
  has_issues: boolean;
  has_projects: boolean;
  has_discussions: boolean;
  created_at: number;
  updated_at: number;
  pushed_at: number;
  visibility: string;
  size: number;
  default_branch: string;
}

export function isGitguruRepo(obj: unknown): obj is GitguruRepo {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "github_rest_id" in obj &&
    "github_graphql_id" in obj &&
    "name" in obj &&
    "full_name" in obj &&
    "private" in obj &&
    "owner_login" in obj &&
    "owner_avatar_url" in obj &&
    "description" in obj &&
    "homepage" in obj &&
    "fork" in obj &&
    "forks_count" in obj &&
    "archived" in obj &&
    "disabled" in obj &&
    "license" in obj &&
    "language" in obj &&
    "stargazers_count" in obj &&
    "watchers_count" in obj &&
    "open_issues_count" in obj &&
    "has_issues" in obj &&
    "has_discussions" in obj &&
    "has_projects" in obj &&
    "created_at" in obj &&
    "updated_at" in obj &&
    "pushed_at" in obj &&
    "visibility" in obj &&
    "size" in obj &&
    "default_branch" in obj
  );
}

export function isGitguruUser(obj: unknown): obj is GitguruUser {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "internal_id" in obj &&
    "github_rest_id" in obj &&
    "github_graphql_id" in obj &&
    "login" in obj &&
    "name" in obj &&
    "email" in obj &&
    "avatar_url" in obj &&
    "company" in obj &&
    "location" in obj &&
    "bio" in obj &&
    "blog" in obj &&
    "hireable" in obj &&
    "twitter_username" in obj &&
    "followers" in obj &&
    "following" in obj &&
    "type" in obj &&
    "created_at" in obj &&
    "updated_at" in obj
  );
}

interface RateLimit {
  used: number;
  remaining: number;
  reset: number;
}

export interface RateLimits {
  core: RateLimit;
  graphql: RateLimit;
  search: RateLimit;
}

interface RateLimitsResponse {
  resources: RateLimits;
}

export function isRateLimitsResponse(obj: unknown): obj is RateLimitsResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "resources" in obj &&
    typeof obj.resources === "object" &&
    obj.resources !== null &&
    "core" in obj.resources &&
    "graphql" in obj.resources &&
    "search" in obj.resources
  );
}

interface GithubRateLimitsResponse {
  resources: {
    core: RateLimit;
    graphql: RateLimit;
    search: RateLimit;
  };
}

export function isGithubRateLimitsResponse(
  data: unknown
): data is GithubRateLimitsResponse {
  return (
    typeof data === "object" &&
    data !== null &&
    "resources" in data &&
    typeof data.resources === "object" &&
    data.resources !== null &&
    "core" in data.resources &&
    "graphql" in data.resources &&
    "search" in data.resources
  );
}
