import { useSession } from "next-auth/react";
import HeroPage from "~/components/layout/HeroPage";
import ConnectGithub from "../layout/Sidebar/ConnectGithub";

export default function HasNoTeamAccountUser() {
  const { status } = useSession();
  if (status !== "unauthenticated") return <></>;
  return (
    <HeroPage>
      <div className="flex h-screen w-1/2  flex-col content-center items-center justify-center">
        <div className="mb-3 text-sm text-gray-600">Step 1 of 2</div>
        <div className="text mb-10 text-3xl font-bold">
          Let{"'"}s get you started!
        </div>
        <ConnectGithub />
        <div className="mt-10 w-1/2 text-center text-sm text-muted">
          By continuing, you acknowledge that you have read, understood and
          agree to our{" "}
          <a className="text-accent" href="https://openq.dev/terms-of-service">
            terms of service
          </a>{" "}
          and{" "}
          <a className="text-accent" href="https://openq.dev/privacy-policy">
            privacy policy
          </a>
          .
        </div>
      </div>
    </HeroPage>
  );
}
