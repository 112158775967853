import { DocumentNode, print } from "graphql";

export function checkGraphqlQuery(documentNode: DocumentNode) {
  const query = print(documentNode);
  if (
    // Good enough check for now
    !query.includes("rateLimit") ||
    !query.includes("used") ||
    !query.includes("remaining") ||
    !query.includes("resetAt")
  ) {
    throw new Error(
      "Query must include rateLimit { used, remaining, resetAt }"
    );
  }
}
