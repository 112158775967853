import { createTRPCClient, httpLink } from "@trpc/client"; // Changed from httpBatchLink to httpLink
import { createTRPCReact } from "@trpc/react-query";
import superjson from "superjson";
import type { AppRouter } from "~/server/api/root";

export const trpc = createTRPCClient<AppRouter>({
  links: [
    httpLink({
      transformer: superjson,
      url: "/api/trpc",
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});

export const api = createTRPCReact<AppRouter>({});
