// STATIC CONSTANTS

import {
  REPO_DEPS_EVALUATION_TOPIC,
  REPO_EVALUATION_TOPIC,
  USER_DEPS_EVALUATION_TOPIC,
  USER_EVALUATION_TOPIC,
} from "./kafkaConstants";

// URLS
export const GITHUB_URL = "https://github.com";

export const REPO_IS_PRIVATE = "REPO_IS_PRIVATE";
export const REPO_IS_EMPTY = "REPO_IS_EMPTY";
export const NOT_FOUND = "NOT_FOUND";
export const NO_COMMITS_AFTER_2020 = "NO_COMMITS_AFTER_2020";
export const REPO_TOO_LARGE = "REPO_TOO_LARGE";
export const TARGET_LIMIT_REACHED = "TARGET_LIMIT_REACHED";
export const SECONDARY_RATE_LIMIT_REACHED = "SECONDARY_RATE_LIMIT_REACHED";
export const TOKENS_EXPIRED = "TOKENS_EXPIRED";

// ERROR MESSAGES
export const USER_NOT_FOUND = `GraphQL query failed: {"type":"NOT_FOUND","path":["user"]`;
export const REPO_NOT_FOUND = `GraphQL query failed: {"type":"NOT_FOUND","path":["repository"]`;

export const REPO_NOT_ADDED_REASONS = [
  REPO_IS_PRIVATE,
  REPO_IS_EMPTY,
  NO_COMMITS_AFTER_2020,
  REPO_TOO_LARGE,
  NOT_FOUND,
  TARGET_LIMIT_REACHED,
];

// Size limits for Campaign Evaluations
export const PERCENTAGE_CONSIDER_DONE = 0.98;
export const MAX_TEAMS_TO_SHOW = 300; // activity chart with top 300 teams
export const MAX_SHARED_CONTRIB_DATA = 500; // shared contributors data limit
export const MAX_SHARED_CONTRIB_REPOS = 2000; // max repos to show in campaign evaluation for shared contribs

export const MAX_DEPS_NUMBER = 100;

export const ONE_MINUTE = 60 * 1000;

export const ONE_DAY = 86400000;

export const NUMBER_OF_RATELIMIT_RETRIES = 5;
export const RATELIMIT_RETRY_PERIOD_MS = 2500;
export const SECONDARY_RATELIMIT_RETRY_PERIOD_MS = ONE_MINUTE * 5;
export const SLEEP_BETWEEN_ADDITIONAL_MENTIONABLE_USERS = 5000; // 5 seconds

export const CAMPAIGN_EVALUATION_CRON_INTERVAL = ONE_MINUTE * 30;
export const CAMPAIGN_EVALUATION_INCREMENT = 20; // percentage increment to publish campaign evaluation
export const CAMPAIGN_EVALUATION_INCREMENT_THRESHOLD = 95; // percentage threshold from which a campaign re-evaluates if there is at least 1 new done evaluation
export const CAMPAIGN_EVALUATION_CRON_ERRORED_INTERVAL = ONE_MINUTE * 60 * 24; // once a day retry for errored campaign evaluations
export const CAMPAIGN_EVALUATION_CRON_DELETION_INTERVAL = ONE_MINUTE * 60 * 24; // once a day retry for deletion of campaigns
export const OVERVIEW_EVALUATION_CRON_INTERVAL = ONE_MINUTE * 30;
export const TARGET_BATCH_CREATION_CRON_INTERVAL = ONE_MINUTE * 10;
export const INTERVAL_BETWEEN_CRONJOBS = ONE_MINUTE * 3;

export const TRANSACTION_OPTIONS = {
  timeout: ONE_MINUTE * 2,
  maxWait: ONE_MINUTE * 2,
};

export const MAX_TARGETS_TO_SHOW = 1000;
export const MAX_ARRAY_SIZE_VENN = 1000;

// Kafka Client Id

// KAFKA CONSUMER CONFIGURATION
export const getEvaluationTopic = (type: string) => {
  switch (type) {
    case "repo":
      return REPO_EVALUATION_TOPIC;

    case "repo-dependencies":
      return REPO_DEPS_EVALUATION_TOPIC;

    case "user":
      return USER_EVALUATION_TOPIC;

    case "user-dependencies":
      return USER_DEPS_EVALUATION_TOPIC;

    default:
      return null;
  }
};
export const MAX_CONSUMER_RESTARTS = 10;
export function getFullYearAgoIso() {
  return new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  ).toISOString();
}
export function getSixWeeksAgoIso() {
  return new Date(new Date().setDate(new Date().getDate() - 42)).toISOString();
}
export function getFourWeeksAgoIso() {
  return new Date(new Date().setDate(new Date().getDate() - 28)).toISOString();
}

export function getCurrentTimestamp() {
  return new Date().toISOString();
}

export function getJan12020Iso() {
  return new Date("2020-01-01").toISOString();
}
